<template>
  <div>
    <b-row>
      <b-col lg="12">
        <b-card-code title="Repertoire des Contacts">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            v-b-modal.modal-add-recever
            variant="outline-primary"
          >
            Ajouter
          </b-button>
          <!-- input search -->
          <div class="custom-search d-flex justify-content-end">
            <b-form-group>
              <div class="d-flex align-items-center">
                <label class="mr-1">Recherche</label>
                <b-form-input
                  v-model="searchTerm"
                  placeholder="Recherche"
                  type="text"
                  class="d-inline-block"
                />
              </div>
            </b-form-group>
          </div>

          <!-- table -->
          <vue-good-table
            :columns="recevercolumns"
            :rows="recevers"
            :rtl="direction"
            :search-options="{
              enabled: true,
              externalQuery: searchTerm,
            }"
            :pagination-options="{
              enabled: true,
              perPage: pageLength,
            }"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >
              <!-- Column: Action -->
              <span v-if="props.column.field === 'action'">
                <span>
                  <b-dropdown
                    variant="link"
                    toggle-class="text-decoration-none"
                    no-caret
                  >
                    <template v-slot:button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="text-body align-middle mr-25"
                      />
                    </template>
                    <b-dropdown-item @click="editRecever(props.row)">
                      <feather-icon
                        icon="Edit2Icon"
                        class="mr-50"
                      />
                      <span>Modifier</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      v-if="props.row.canGetEmail===0"
                      @click="toggleAcount(props.row)"
                    >
                      <feather-icon
                        icon="Edit2Icon"
                        class="mr-50"
                      />
                      <span>Activer la reception de mail</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      v-else-if="props.row.canGetEmail===1"
                      @click="toggleAcount(props.row)"
                    >
                      <feather-icon
                        icon="Edit2Icon"
                        class="mr-50"
                      />
                      <span>Desactiver la reception de mail</span>
                    </b-dropdown-item>
                    <!-- <b-dropdown-item>
                      <feather-icon
                        icon="TrashIcon"
                        class="mr-50"
                      />
                      <span>Delete</span>
                    </b-dropdown-item> -->
                  </b-dropdown>
                </span>
              </span>
              <span v-else-if="props.column.field === 'canGetEmail'">
                <b-badge :variant="statusVariant(props.row.status)">
                  {{ props.row.canGetEmail == 1 ? "OUI" : "NON" }}
                </b-badge>
              </span>
              <!-- Column: Common -->
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>

            <!-- pagination -->
            <template
              slot="pagination-bottom"
              slot-scope="props"
            >
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap"> Showing 1 to </span>
                  <b-form-select
                    v-model="pageLength"
                    :options="['10', '20', '50']"
                    class="mx-1"
                    @input="
                      (value) => props.perPageChanged({ currentPerPage: value })
                    "
                  />
                  <span class="text-nowrap">
                    of {{ props.total }} entries
                  </span>
                </div>
                <div>
                  <b-pagination
                    :value="1"
                    :total-rows="props.total"
                    :per-page="pageLength"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="
                      (value) => props.pageChanged({ currentPage: value })
                    "
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
          <!--    Modal Add-->
          <b-modal
            id="modal-add-recever"
            cancel-variant="outline-secondary"
            ok-title="Enregistrer"
            cancel-title="Close"
            :ok-disabled="desableButton"
            :cancel-disabled="desableButton"
            centered
            size="lg"
            title="Ajouter une Adresse"
            @ok="addRecever"
          >
            <b-overlay
              :show="showOverlay"
              spinner-variant="primary"
              spinner-type="grow"
              label="Spinning"
              spinner-large
              rounded="sm"
            >
              <validation-observer ref="simpleRules">
                <b-form>
                  <b-row>
                    <b-col
                      md="6"
                      lg="6"
                    >
                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="Nom"
                          rules="required"
                        >
                          <b-form-input
                            v-model="recever.fullName"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Nom"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col
                      md="6"
                      lg="6"
                    >
                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="Télephone"
                          rules="required"
                        >
                          <b-form-input
                            v-model="recever.phone"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Télephone"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col
                      md="6"
                      lg="6"
                    >
                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="Email"
                          rules="required"
                        >
                          <b-form-input
                            v-model="recever.email"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Email"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col
                      md="6"
                      lg="6"
                    >
                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="Plateforme"
                          vid="plateforme"
                          rules="required"
                        >
                          <b-form-select
                            v-model="recever.plateforme"
                            :options="plateformes"
                            :state="errors.length > 0 ? false : null"
                          />

                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col
                      md="6"
                      lg="6"
                    >
                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="Status"
                          vid="status"
                          rules="required"
                        >
                          <b-form-select
                            v-model="recever.status"
                            :options="statusList"
                            :state="errors.length > 0 ? false : null"
                          />

                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col
                      v-if="recever.plateforme==='Autre' && recever.status==='Autre'"
                      md="6"
                      lg="6"
                    >
                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="Autre status"
                          rules="required"
                        >
                          <b-form-input
                            v-model="recever.otherStatus"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Autre status"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-form>
              </validation-observer>

            </b-overlay>
          </b-modal>
          <!--    Modal Edit-->
          <b-modal
            id="modal-edit-recever"
            cancel-variant="outline-secondary"
            ok-title="Mettra a jour"
            cancel-title="Close"
            :ok-disabled="desableButton"
            :cancel-disabled="desableButton"
            centered
            size="lg"
            title="Ajouter une Adresse"
            @ok="updateRecever"
          >
            <b-overlay
              :show="showOverlay"
              spinner-variant="primary"
              spinner-type="grow"
              label="Spinning"
              spinner-large
              rounded="sm"
            >
              <validation-observer ref="simpleRules">
                <b-form>
                  <b-row>
                    <b-col
                      md="6"
                      lg="6"
                    >
                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="Nom"
                          rules="required"
                        >
                          <b-form-input
                            v-model="recever.fullName"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Nom"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col
                      md="6"
                      lg="6"
                    >
                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="Télephone"
                          rules="required"
                        >
                          <b-form-input
                            v-model="recever.phone"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Télephone"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col
                      md="6"
                      lg="6"
                    >
                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="Email"
                          rules="required"
                        >
                          <b-form-input
                            v-model="recever.email"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Email"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col
                      md="6"
                      lg="6"
                    >
                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="Plateforme"
                          vid="plateforme"
                          rules="required"
                        >
                          <b-form-select
                            v-model="recever.plateforme"
                            :options="plateformes"
                            :state="errors.length > 0 ? false : null"
                          />

                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col
                      md="6"
                      lg="6"
                    >
                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="Status"
                          vid="status"
                          rules="required"
                        >
                          <b-form-select
                            v-model="recever.status"
                            :options="statusList"
                            :state="errors.length > 0 ? false : null"
                          />

                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col
                      v-if="recever.plateforme==='Autre' && recever.status==='Autre'"
                      md="6"
                      lg="6"
                    >
                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="Autre status"
                          rules="required"
                        >
                          <b-form-input
                            v-model="recever.otherStatus"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Autre status"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-form>
              </validation-observer>

            </b-overlay>
          </b-modal>
        </b-card-code>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BButton,
  BForm,
  BModal,
  BFormSelect,
  BRow,
  BCol,
  BPagination,
  BFormGroup,
  BFormInput,
  BDropdown,
  BDropdownItem,
  BOverlay,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import receverMixin from '../@core/mixins/receverMixin'
import axios from '../libs/axios'

export default {
  components: {
    BCardCode,
    VueGoodTable,
    ValidationProvider,
    ValidationObserver,
    BFormSelect,
    BRow,
    BCol,
    BModal,
    BForm,
    BButton,
    BPagination,
    BFormGroup,
    BFormInput,
    BDropdown,
    BDropdownItem,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  mixins: [receverMixin],
  data() {
    return {
      required,
      pageLength: 10,
      dir: false,
      showOverlay: false,
      desableButton: false,
      plateformes: [
        { value: null, text: 'Veuillez sélectionner une plateforme' },
        { value: 'Plateforme Locale', text: 'Plateforme Locale' },
        { value: 'Plateforme communale', text: 'Plateforme communale' },
        { value: 'Plateforme départementale', text: 'Plateforme départementale' },
        { value: 'Plateforme nationale', text: 'Plateforme nationale' },
        { value: 'Autre', text: 'Autre' },

      ],
      statusList: [
        { value: null, text: 'Veuillez sélectionner un status' },
        { value: 'Président', text: 'Président' },
        { value: '1er Vice Pres', text: '1er Vice Pres' },
        { value: '2eme Vice Pres', text: '2eme Vice Pres' },
        { value: '3eme Vice Pres', text: '3eme Vice Pres' },
        { value: 'Point focal', text: 'Point focal' },
        { value: 'Membre', text: 'Membre' },
        { value: 'Autre', text: 'Autre' },

      ],
      recevercolumns: [
        {
          label: 'Nom',
          field: 'fullName',
          filterOptions: {
            enabled: true,
            placeholder: 'Recherche Par Nom',
          },
        },
        {
          label: 'Télephone',
          field: 'phone',
          filterOptions: {
            enabled: true,
            placeholder: 'Recherche Par telephone',
          },
        },
        {
          label: 'Email',
          field: 'email',
          filterOptions: {
            enabled: true,
            placeholder: 'Recherche Par email',
          },
        },
        {
          label: 'Plateforme',
          field: 'plateforme',
          filterOptions: {
            enabled: true,
            placeholder: 'Recherche Par plateforme',
          },
        },
        {
          label: 'Status',
          field: 'status',
          filterOptions: {
            enabled: true,
            placeholder: 'Recherche Par zone',
          },
        },
        {
          label: 'Peux recevoir',
          field: 'canGetEmail',
        },

        {
          label: 'Action',
          field: 'action',
        },
      ],
      rows: [],
      searchTerm: '',
      recever: {
        fullName: null,
        phone: null,
        email: null,
        plateforme: null,
        status: null,
        otherStatus: null,
        canGetEmail: 0,
      },
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: 'light-primary',
        Professional: 'light-success',
        Rejected: 'light-danger',
        Resigned: 'light-warning',
        Applied: 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  watch: {
    'recever.plateforme': function (newValue) {
      if (newValue === 'Autre') {
        this.recever.status = 'Autre'
      } else { this.recever.status = null }
    },
    'recever.status': function (newValue) {
      if (newValue === 'Autre') {
        this.recever.plateforme = 'Autre'
      }
    },

  },
  created() {
  },
  methods: {
    getImage(link) {
      // eslint-disable-next-line no-undef
      return `${axios.defaults.baseURL}/${link}`
    },
    addRecever(e) {
      e.preventDefault()
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.showOverlay = true
          this.desableButton = true
          if (this.recever.status === 'Autre' && this.recever.otherStatus != null) {
            this.recever.status = this.recever.otherStatus
          }
          this.$store
            .dispatch('mainAppStore/addReceverItem', this.recever)
            .then(response => {
              console.log(response.data)
              this.showOverlay = false
              this.desableButton = false
              this.$bvModal.hide('modal-add-recever')
              this.$swal({
                title: 'Succes',
                html: 'Sous Recever ajoutée avec succes',
                icon: 'success',
                timer: 3000,
                showConfirmButton: false,
              })
            })
            .catch(err => {
              this.showOverlay = false
              this.desableButton = false
              this.$swal({
                title: 'Erreur',
                html: err.response.data.message,
                icon: 'error',
                timer: 3000,
                showConfirmButton: false,
              })
              console.log(err)
              console.error(err.response)
            })
        }
      })
    },
    editRecever(recever) {
      this.recever.id = recever.id
      this.recever.fullName = recever.fullName
      this.recever.phone = recever.phone
      this.recever.email = recever.email
      this.recever.plateforme = recever.plateforme
      this.recever.status = recever.status
      this.recever.canGetEmail = recever.canGetEmail
      this.$bvModal.show('modal-edit-recever')
    },
    toggleAcount(recever) {
      const user = recever
      if (user.canGetEmail === 0) {
        user.canGetEmail = 1
      } else {
        user.canGetEmail = 0
      }
      this.$store.dispatch('mainAppStore/updateReceverItem', user).then(response => {
        console.log(response.data)
        this.$bvModal.hide('modal-edit-account')
        this.$swal({
          title: 'Succes',
          html: 'modifié avec succes',
          icon: 'success',
          timer: 3000,
          showConfirmButton: false,
        })
      })
        .catch(err => {
          this.$swal({
            title: 'Erreur',
            html: err.response.data.message,
            icon: 'error',
            timer: 3000,
            showConfirmButton: false,
          })
          console.error(err.response.headers)
        })
    },
    updateRecever(e) {
      e.preventDefault()
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.showOverlay = true
          this.desableButton = true
          if (this.recever.status === 'Autre' && this.recever.otherStatus != null) {
            this.recever.status = this.recever.otherStatus
          }
          this.$store
            .dispatch('mainAppStore/updateReceverItem', this.recever)
            .then(response => {
              console.log(response.data)
              this.showOverlay = false
              this.desableButton = false
              this.$bvModal.hide('modal-edit-recever')
              this.$swal({
                title: 'Succes',
                html: `${response.data.name} est modifier avec succes`,
                icon: 'success',
                timer: 3000,
                showConfirmButton: false,
              })
            })
            .catch(err => {
              this.showOverlay = false
              this.desableButton = false
              this.$swal({
                title: 'Erreur',
                html: err.response.data.message,
                icon: 'error',
                timer: 3000,
                showConfirmButton: false,
              })
              console.error(err.response.headers)
            })
        }
      })
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
